<template>
  <div>
    <b-modal
      id="formLaporan"
      ok-only
      centered
      size="lg"
      content-class="shadow"
      hide-footer
    >
    <template #modal-title> <div style="font-size:24px; font-weight:600;">Semester {{ semester == 1 ? 'I (Satu)' : 'II (Dua)' }} Tahun {{year}} </div></template>
      <div>
        <b-form @submit.stop.prevent="submitLaporan">
        <b-form-row>
          <b-col>
            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Nama kegiatan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-form-input
                  type="text"
                  placeholder="Masukkan nama kegiatan"
                  style="padding-left:10px;"
                   v-model="$v.form.nama_kegiatan.$model"
                    :state="validateState('nama_kegiatan')"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Jenis kegiatan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-form-select
                  type="text"
                  placeholder="Pilih Jenis Kegiatan "
                  style="padding-left:10px;"
                  v-model="$v.form.m_jenis_kegiatan_id.$model"
                  :state="validateState('m_jenis_kegiatan_id')"
                >
                <b-form-select-option :value="item.id" v-for="(item,index) in listKegiatan" :key="index">{{item.namaJenisKegiatan}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center pr-0">
                <span style="font-weight: 600">Tempat kegiatan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-form-input
                  type="text"
                  placeholder="Masukkan tempat kegiatan"
                  style="padding-left:10px;"
                  v-model="$v.form.tempat_kegiatan.$model"
                  :state="validateState('tempat_kegiatan')"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mb-2"> 
              <b-col sm="2" class="d-flex justify-content-start align-items-center pr-0">
                <span style="font-weight: 600;">Tanggal kegiatan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-form-datepicker
                  type="text"
                  placeholder="Pilih tanggal kegiatan"
                  style="padding-left:10px;"
                  
                  v-model="$v.form.tanggal_kegiatan.$model"
                  :state="validateState('tanggal_kegiatan')"
                ></b-form-datepicker>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Sumber Dana</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-form-select
                  type="text"
                  placeholder="Pilih jenis sumber dana kegiatan"
                  style="padding-left:10px;"
                  v-model="$v.form.m_sumber_dana_id.$model"
                  :state="validateState('m_sumber_dana_id')"
                >
                <b-form-select-option :value="item.id" v-for="(item,index) in listSumberDana" :key="index">{{item.namaSumberDana}}</b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Keterangan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-form-textarea
                  id="textarea"
                  placeholder="Masukan keterangan kegiatan"
                  rows="3"
                  :state="validateState('keterangan')"
                  v-model="$v.form.keterangan.$model"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2  " class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Unggah File</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-button v-if="file_laporan == ''" variant="primary" size="sm" @click="fileClicked()"><v-icon>mdi-file-plus</v-icon> Unggah File</b-button>
                    <!-- <a :href="fileURL + file_logo" target="_blank" type="button">Lihat File</a> -->
                    <a v-if="file_laporan != ''" :href="fileURL + file_laporan" target="_blank" type="button">
                    <b-button variant="success" size="sm">
                      Lihat File
                    </b-button>
                    </a>
                    <b-button v-if="file_laporan != ''" @click="file_laporan = ''" variant="danger" size="sm" class="ml-3">
                      <v-icon class="p-0">mdi-delete-outline</v-icon>
                    </b-button>

                <input style="display: none;" @change="uploadFile" type="file" ref="fileInput"/>
              </b-col>
            </b-row>

          </b-col>
        </b-form-row>
        <div class="text-center">
          <b-button
            variant="outline-primary"
            class="mt-16 text-center mr-5"
            @click="$bvModal.hide('formLaporan')"
          >
            Batal
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            class="mt-16 text-center"
          >
            {{createMode ? "Tambah Laporan" : "Ubah Laporan"}}
          </b-button>
        </div>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      id="detailLaporan"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-footer
    >
    <template #modal-title> <div style="font-size:18px;; font-weight:600;">Detail Laporan </div></template>
      <div style="width:78%;">
        <b-form-row>
          <b-col>
            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Nama kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{ dataLaporan.nama_kegiatan }}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Jenis kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{ dataLaporan.masterJenisKegiatan.nama_jenis_kegiatan }}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Tempat kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{ dataLaporan.tempat_kegiatan}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2"> 
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Tanggal kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{ dataLaporan.tanggal_kegiatan | moment('DD MMMM YYYY')}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Sumber Dana</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{dataLaporan.masterSumberDana.nama_sumber_dana}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-start">
                <span style="font-weight: 600">keterangan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-start">
                <span class="pr-3">:</span>
                <span>{{dataLaporan.keterangan}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Unggah File</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-button size="sm" @click="newTab(dataLaporan.file_laporan)" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button>
              </b-col>
            </b-row>

          </b-col>
        </b-form-row>
      </div>
    </b-modal>

    <b-modal
      id="formKonfirmasi"
      ok-only
      centered
      size="md"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container>
          <p style="font-size:18px; margin-top:20px;">
            Apakah anda yakin ingin Menghapus Laporan?
          </p>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            @click="$bvModal.hide('formKonfirmasi')"
          >
            Tidak
          </b-button>
          <b-button
            variant="danger"
            class="mt-16 text-center"
            @click="deleteLaporan()"
          >
            Yakin
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="formInfo"
      ok-only
      centered
      size="md"
      content-class="shadow"
      hide-header
      hide-footer
    >
      <div>
        <b-container class="text-center">
          <p style="font-size:18px; margin-top:20px;">
            Anda belum memasukkan pelaporan kegiatan
            semester 1 tahun 2021
          </p>

          <p class="pt-4">Apakah ada kegiatan yang akan dilaporkan?</p>
        </b-container>
        <div class="text-center">
          <b-button
            variant="outline-secondary"
            class="mt-16 text-center mr-5"
            @click="confirmaddSemester2()"
          >
            Tidak
          </b-button>
          <b-button
            variant="primary"
            class="mt-16 text-center"
            @click="addSemester1()"
          >
            Ya
          </b-button>
        </div>
      </div>
    </b-modal>


    <b-row class="mb-5">
      <b-col>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          <b>Pelaporan Kegiatan</b>
        </h5>
      </b-col>
    </b-row>
    <b-card class="shadow">
      <!-- <b-container> -->
        <b-row class="mb-3">
          <b-col cols="6">
            <b-button variant="light" size="lg" v-b-toggle.collapse-1 v-if="semester1.length > 0 || semester2.length > 0" style="width:270px;">
              Semester 1 (Satu) Tahun {{year}}
              <v-icon medium v-if="collapse1">mdi-chevron-up</v-icon>
              <v-icon medium v-if="!collapse1">mdi-chevron-down</v-icon>
            </b-button>
            <b-button variant="light" size="lg" v-else style="width:270px;">
              Semester 1 (Satu) Tahun {{year}}
            </b-button>
             <b-button variant="primary" style="margin-left:16px" @click="addSemester1()">
              <v-icon style="color:white;" small>mdi-plus-box</v-icon>
              Tambah Laporan
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-collapse id="collapse-1" class="mt-2" v-model="collapse1">
                <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px" class="text-capitalize">No.</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Jenis Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Tempat Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Tanggal Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Status</th>
                          <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-if="semester1.length ==  0">
                          <tr style="83px;">
                           <td colspan="7">
                            -- Tidak Ada Kegiatan --
                           </td> </tr>
                        </template>
                        <template v-for="(item,index) in semester1">
                          <tr :key="index" v-if="semester1.length > 0">
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ index + 1}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "> {{ item.nama_kegiatan }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.masterJenisKegiatan.nama_jenis_kegiatan}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.tempat_kegiatan}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ item.tanggal_kegiatan | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        " >{{item.status == 0 ? 'Sedang Ditinjau' : item.status == 1 ? 'Diterima' : 'Ditolak'}}</span>
                            </td>
                            <td>
                              <b-button v-if="item.status == 0" variant="danger" size="sm" style="padding:5px" class="mr-1" @click="deleteConfirm(item.id)">
                                <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                              </b-button>
                              <b-button v-if="item.status == 0" variant="primary" size="sm" style="padding:5px" class="mr-1">
                                <v-icon style="color:white;padding:0px;" @click="editLaporan(item.id)" small>mdi-pencil</v-icon>
                              </b-button>
                              <b-button v-if="item.status == 1" variant="success" size="sm" style="padding:5px" @click="showDetail(item.id)">
                                >>
                                <!-- <v-icon style="color:white;" small>mdi-chevron-right</v-icon>
                                <v-icon style="color:white;" small>mdi-chevron-right</v-icon> -->
                              </b-button> 
                            </td>
                          </tr>
                          
                        </template>
                      </tbody>
                    </table>
                  </div>
            </b-collapse>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-button variant="light" size="lg" v-b-toggle.collapse-2 v-if="semester2.length > 0" style="width:270px;">
              Semester 2 (Dua) Tahun {{year}}
              <v-icon medium v-if="collapse2">mdi-chevron-up</v-icon>
              <v-icon medium v-if="!collapse2">mdi-chevron-down</v-icon>
            </b-button>
            <b-button variant="light" size="lg" style="width:270px;" v-else>
              Semester 2 (Dua) Tahun {{year}}
            </b-button>
            <!-- :class="quarter < 3 ? 'sms2-btn-op' : ''" -->
            
            <b-button variant="primary" :disabled="quarter < 3"  style="margin-left:16px;" @click="addSemester2()">
              <v-icon style="color:white;" small>mdi-plus-box</v-icon>
              Tambah Laporan
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-collapse id="collapse-2" class="mt-2" v-model="collapse2">
                <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px" class="text-capitalize">No.</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Jenis Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Tempat Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Tanggal Kegiatan</th>
                          <th style="min-width: 50px" class="text-capitalize">Status</th>
                          <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-if="semester2.length ==  0">
                          <tr style="83px;">
                           <td colspan="7">
                            -- Tidak Ada Kegiatan --
                           </td> </tr>
                        </template>
                        <template v-for="(item,index) in semester2" >
                          <tr :key="index">
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ index + 1}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "> {{ item.nama_kegiatan }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{item.masterJenisKegiatan.nama_jenis_kegiatan}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{item.tempat_kegiatan}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{ item.tanggal_kegiatan | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        " >{{item.status == 0 ? 'Sedang Ditinjau' : item.status == 1 ? 'Diterima' : 'Ditolak'}}</span>
                            </td>
                            <td>
                              <b-button v-if="item.status == 0" variant="danger" size="sm" class="mr-1" style="padding:5px" @click="deleteConfirm(item.id)">
                                <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                              </b-button>
                              <b-button v-if="item.status == 0" variant="primary" size="sm" style="padding:5px" class="mr-1">
                                <v-icon style="color:white;padding:0px;" @click="editLaporan(item.id)" small>mdi-pencil</v-icon>
                              </b-button> 
                              <b-button v-if="item.status == 1" variant="success" size="sm" style="padding:5px" @click="showDetail(item.id)">
                                >>
                                <!-- <v-icon style="color:white;" small>mdi-chevron-right</v-icon>
                                <v-icon style="color:white;" small>mdi-chevron-right</v-icon> -->
                              </b-button> 
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
            </b-collapse>
          </b-col>
        </b-row>
      <!-- </b-container> -->
    </b-card>
  </div>
</template>
<style>
.sms2-btn-op {
 opacity:0.38; margin-left:16px;
}
</style>
<script>
import { validationMixin } from "vuelidate";  
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  mixins: [validationMixin],
  name: "Pelaporan",
  data() {
    return {
      year: new Date().getFullYear(),
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      loadUpload: false,
      file_laporan: '',
      collapse1: false,
      collapse2: false,
      semester1: [],
      semester2: [],
      semester: 1,
      selectedID: '',
      listSumberDana: [],
      listKegiatan: [],
      form: {
          nama_kegiatan: '',
          tempat_kegiatan: '',
          tanggal_kegiatan: '',
          keterangan: '',
          m_jenis_kegiatan_id: '',
          m_sumber_dana_id: '',
          file_laporan: ''
      },
      createMode: true,
      dataLaporan : {
        nama_kegiatan: '',
          tempat_kegiatan: '',
          tanggal_kegiatan: '',
          keterangan: '',
          masterJenisKegiatan: {
            nama_jenis_kegiatan : ''
          },
          masterSumberDana: {
            nama_sumber_dana: ''
          }
      },
      minDate: '',
      maxDate: '',
      quarter: null
    }
  },
  validations: {
    form: {
      nama_kegiatan: {
        required
      },
      tempat_kegiatan: {
        required
      },
      tanggal_kegiatan: {
        required
      },
      keterangan: {
        required
      },
      m_jenis_kegiatan_id: {
        required
      },
      m_sumber_dana_id: {
        required
      }
    },
  },
  watch: {
    semester(){
      this.setDate()
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    fileClicked(){
      setTimeout(() => {
        this.$refs.fileInput.click()  
      }, 500);
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadDocumentUmum', formData).then((res) =>{

        if(res.data.status != false){
          // "path": res.data.data.newFilePath,
          this.file_laporan = res.data.data.newFilePath
          Swal.fire({
            title: "Upload File Berhasil",
            text: '',
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          })
        }
        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
    getPelaporanS1(){
      this.$store
        .dispatch("getPelaporanS1", 1)
        .then((res) => {
          this.semester1 = res.data.data
          if(res.data.data.length > 0) {
            this.collapse1 = true
          }
        })
    },
    getPelaporanS2(){
      this.$store
        .dispatch("getPelaporanS2", 1)
        .then((res) => {
          this.semester2 = res.data.data
          if(res.data.data.length > 0) {
            this.collapse2 = true
          }
        })
    },
    newTab(url){
      if(url != '' || url != null){
      window.open(this.fileURL + '/' + url);
      }
    },
    emptyForm(){
      this.form = {
          nama_kegiatan: '',
          tempat_kegiatan: '',
          tanggal_kegiatan: '',
          keterangan: '',
          m_jenis_kegiatan_id: '',
          m_sumber_dana_id: '',
          file_laporan: ''
          }
    },
    confirmaddSemester2(){
      this.$nextTick(() => { this.$v.$reset() })
      this.semester = 2
      this.createMode = true
      this.emptyForm()
      this.$bvModal.show('formLaporan')
      this.$bvModal.hide('formInfo')
    },
    addSemester2() {
      this.$nextTick(() => { this.$v.$reset() })
      this.semester = 2
      this.createMode = true
      this.file_laporan = ''
      this.emptyForm()
      if(this.semester1.length == 0 && this.semester2.length == 0 ) this.$bvModal.show('formInfo')
      else this.$bvModal.show('formLaporan')
    },
    addSemester1() {
      this.$nextTick(() => { this.$v.$reset() })
      this.emptyForm()
      this.createMode = true
      this.file_laporan = ''
      this.semester = 1
      this.$bvModal.show('formLaporan')
      this.$bvModal.hide('formInfo')
      
    },
    saveToDraft(){
      // alert('no API')
    },
    submitLaporan(){
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if(this.createMode){
        this.form.tanggal_kegiatan = this.form.tanggal_kegiatan + "T00:00:00.000Z"
        this.form.file_laporan = this.file_laporan
        // this.form.tanggal_pelaporan = this.$moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSz')
        this.$store
        .dispatch("submitPelaporan", this.form)
        .then((/*res*/) => {
          this.$bvModal.hide('formLaporan')
          this.emptyForm()
          this.fetch()
        })
      } else {
        this.form.tanggal_kegiatan = this.$moment(this.form.tanggal_kegiatan).format('YYYY-MM-DDTHH:mm:ss.SSSz')
        // this.form.tanggal_pelaporan = this.$moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSz')
        this.form.file_laporan = this.file_laporan
        this.$store
        .dispatch("patchPelaporan", [this.form,this.selectedID])
        .then((/*res*/) => {
          this.$bvModal.hide('formLaporan')
          this.emptyForm()
          this.fetch()
        })
      }
      
    },
    deleteConfirm(id){
      this.selectedID = id
      this.$bvModal.show('formKonfirmasi')
    },
    deleteLaporan(){
      this.$store
        .dispatch("deletePelaporan", this.selectedID)
        .then((/*res*/) => {
          this.$bvModal.hide('formKonfirmasi')
          this.fetch()
        })
    },
    editLaporan(id){
      this.detailLaporan(id)
      this.$bvModal.show('formLaporan')
    },
    showDetail(id){
      this.detailLaporan(id)
      this.$bvModal.show('detailLaporan')
    },
    detailLaporan(id){
      this.createMode = false
      this.selectedID = id
      this.$store
        .dispatch("detailLaporan", id)
        .then((response) => {
          let res = response.data
          this.form = {
              nama_kegiatan: res.nama_kegiatan,
              tempat_kegiatan: res.tempat_kegiatan,
              tanggal_kegiatan: res.tanggal_kegiatan,
              keterangan: res.keterangan,
              m_jenis_kegiatan_id: res.m_jenis_kegiatan_id,
              m_sumber_dana_id: res.m_sumber_dana_id,
              file_laporan: res.file_laporan
            }
            this.file_laporan = res.file_laporan
            this.dataLaporan = response.data
        })
    },
    fetch(){
      this.getPelaporanS1()
      this.getPelaporanS2()
      this.$forceUpdate()
    },
    seconds_since_epoch(d){ 
        return Math.floor( d / 1000 ); 
    },
    setDate(){
      /* eslint-disable */
      if(this.semester == 1) {
        this.minDate = this.year + '-01-01'
        if(this.seconds_since_epoch(new Date()) > this.seconds_since_epoch(new Date(this.year + '-06-30T23:59:59Z'))) {
          this.maxDate = this.year + '-06-30'
        } else {
          this.maxDate = this.$moment(new Date()).format('YYYY-MM-DD');
        }
      } else {
        this.minDate = this.year + '-07-01'
          this.maxDate = this.$moment(new Date()).format('YYYY-MM-DD');
      }
    },
    getMaster(){
      this.$store
        .dispatch("getJenisKegiatan")
        .then((res) => {this.listKegiatan = res.data})
        this.$store
        .dispatch("getSumberDana")
        .then((res) => {this.listSumberDana = res.data})
    }
  },
  mounted(){
    this.fetch()
    this.setDate()
    this.getMaster()
    this.quarter = this.$moment().quarter()
  }
};
</script>
<style>
h2
{
    font-size: 24px;
}
</style>